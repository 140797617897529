@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-image-class-content {
  background-image: url("./assets/images/backgroundImg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.story-background {
  background-image: url("./assets/images/backgroundStory.jpg");
  object-fit: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.ReactModal__Overlay {
  z-index: 1000;
}
/* navbar custom classes */
.custom-stroke-black circle,
.custom-stroke-black path {
  stroke: rgb(0, 0, 0);
}
.custom-hover-stroke-black:hover circle,
.custom-hover-stroke-black:hover path {
  stroke: rgb(0, 0, 0);
}
.custom-stroke-grey circle,
.custom-stroke-grey path {
  stroke: grey;
}

/* webkit scrollbar   */
/* Hide the default scrollbar */
/* ::-webkit-scrollbar {
  display: none;
} */
/* Apply styles to the custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px; /* Width of the scrollbar track */
}

.small-bar::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: #191919; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Color when hovering over the thumb */
}

::-webkit-scrollbar-track {
  background-color: #1f1f1f; /* Color of the scrollbar track */
}

progress.error::-webkit-progress-value {
  background: red;
}

@media screen and (max-width: 1181px) {
  .custom-table ::-webkit-scrollbar-track {
    background-color: rgb(155, 0, 0);
  }
  .custom-table ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

/* Custom CSS for hamburger icon animation */
.hamburger-line {
  width: 25px;
  height: 2px;
  background-color: white;
  margin: 5px 0;
  transition: all 0.3s;
}
.hamburger-icon.open .hamburger-line:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}
.hamburger-icon.open .hamburger-line:nth-child(2) {
  opacity: 0;
}
.hamburger-icon.open .hamburger-line:nth-child(3) {
  transform: rotate(-45deg) translate(4px, -5px);
}

/* CSS to hide and translate the mobile menu */
.mobile-menu {
  transform: translateX(-100%);
  transition: transform 0.3s;
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 20;
}
.mobile-menu.open {
  transform: translateX(0);
}
.mobile-menu ul {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  list-style: none;
  margin: 0;
}
.mobile-menu ul li {
  margin-bottom: 1rem;
}
.mobile-menu ul li a {
  color: #fff;
  font-weight: bold;
}

.react-tel-input {
  font-size: 1rem !important;
  background: #191919 !important;
  border: none !important;
}

.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 2.5rem !important;
  position: relative;
  border: none !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  font-size: 0.9rem !important;
  background: #191919 !important;
}

.RichTextEditor__root___2QXK- {
  border: none !important;
  border-radius: 8px !important;
  color: #868686 !important;
  min-height: 200px !important;
  border: solid 1px rgb(202, 202, 202) !important;
}

.react-datetime-picker__wrapper {
  display: flex !important;
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  border: none !important;
  background: #191919 !important;
  border-radius: 5px !important;
  height: 40px !important;
  padding: 4px !important;
  width: 100% !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.react-datetime-picker__button__icon {
  stroke: inherit;
}

.react-datetime-picker__calendar .react-calendar {
  border-width: thin !important;
  background: #191919 !important;
  border-radius: 10px !important;
  z-index: 100 !important;
}

.react-calendar__tile--now {
  background: #0d9604 !important;
  border-radius: 5px !important;
}

/* react-datepicker custom styling */

.custom-datepicker .react-datepicker__close-icon::after {
  background: none;
  font-size: 20px;
}

.custom-datepicker .react-datepicker__input-container .react-datepicker__calendar-icon {
  top: 50%;
  transform: translateY(-50%);
}

.custom-datepicker .react-datepicker__input-container .react-datepicker__close-icon {
  top: 46%;
  transform: translateY(-50%);
}

/* percentage of uploading file with loader */
.uploading {
  position: relative;
  color: #fff;
  font-size: 24px;
  padding: 15px;
  right: 52%;
  top: 5px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.date-range-container {
  position: relative;
}

.date-range-btn {
  cursor: pointer;
  padding: 0px 8px;
  @apply bg-yellowish/20 text-yellowish;
}

.date-range-btn button {
  font-size: 0.75rem;
  font-weight: 600;
  border: none;
  position: relative;
}

.date-range-btn button span {
  position: absolute;
  top: 0px;
  left: 3px;
  font-size: 0.45rem;
  font-weight: 700;
}

.date-range-picker {
  position: absolute;
  top: 0px;
  right: 0px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  z-index: 100;
  top: 100%;
}

.date-range-picker.startDate {
  right: 50%;
}
.date-range-picker.endDate {
  right: 0%;
}

.filepond--credits {
  display: none;
}

.custom-drop-shadowV1 {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.\!z-\[1000\] {
  z-index: 1000 !important;
}

.ReactCrop__child-wrapper {
  display: flex;
  justify-content: center;
}

.enable-list-style ul{
  list-style: disc;
  margin: auto;
  padding-left: 1rem;
}

.enable-list-style ol{
  list-style: decimal;
  margin: auto;
  padding-left: 1rem;
}
